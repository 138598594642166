

























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import TriggerForm from "./components/TriggerForm.vue";
import OffersTable from "./components/OffersTable.vue"


export default Vue.extend({
  name: "LightningOffers",

  components: {
    FullScreenDialog,
    TriggerForm,
    OffersTable
  },

  data: () => ({
    fullScreenDialog: false
  }),

  computed: {
    ...mapGetters({
      offers: "offer/getLightningOffers",
    })
  },

  mounted() {
    this.$store.dispatch("offer/fetchLightningOffer");
  },

  methods: {
    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
    },
  }
})
