


















import Vue from "vue"
import { TelegramChannel } from "@/store/types";

export default Vue.extend({
  name: "OffersTable",

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    showDeleteDialog: false,
    channelToDelete: null as unknown | TelegramChannel,
    headers: [
      {
        text: "Imagem",
        value: "thumbnail",
        sortable: false,
      },
      {
        text: "Título",
        align: "start",
        value: "name",
      },
      { text: "Loja", value: "store.name", align: "center" },
      { text: "Preço", value: "price", align: "center" },
    ] as Array<Object>
  }),
})
