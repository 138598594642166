














































import Vue from "vue";
import { OfferBySKU } from "@/store/types/offer";

export default Vue.extend({
  name: "TriggerForm",

  props: { editOffer: { type: Object, default: null } },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        id: "",
        sku: "",
        telegram: false,
        notification: false,
        whatsapp: false,
        facebook: false,
        event_date: new Date(),
      } as OfferBySKU
    };
  },

  methods: {
    trigger(): void {
      if (!this.validateForm()) return;

      this.$store.dispatch("offer/triggerLightningOffer", this.payload);

      this.$emit("close");
    },

    validateForm(): Boolean {
      if (!this.payload.sku) {
        this.showError("Insira o SKU da oferta!");
        return false;
      }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    }
  }
});
